import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import ImageWrap from 'components/ImageWrap'
import PageLink from 'components/PageLink'
import TitleCopy from 'components/TitleCopy'
import TitleCopy2 from 'components/TitleCopy2'
import BannerMid from 'components/BannerMid'
import { strings } from 'js/strings'

export default function PlatformTemplate({ data }) {
  const page = data.wpPage
  const { bannerMidPlatform, infographic, list, titleCopy, titleCopy2 } =
    page.template.platform

  return (
    <Layout
      bodyClassName='page-template page-template-page-builder page-builder page page-id-135 page-parent gps-enterprise-platform app-data index-data singular-data page-data page-135-data page-gps-enterprise-platform-data page-builder-data'
      page={page}
    >
      <TitleCopy content={titleCopy} />
      <PlatformInfographic content={infographic} />
      <BannerMid content={bannerMidPlatform} />
      <TitleCopy2 content={titleCopy2} />
      <List content={list} />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      title
      ...SeoPage
      ...BannerPage
      ...RelatedBlockPage
      ...ContactBlockPage
      template {
        templateName
        ... on WpTemplate_Platform {
          platform {
            titleCopy {
              title
              body
            }
            infographic {
              main {
                icon {
                  ...GatsbyImage
                }
              }
              sub {
                items {
                  icon {
                    ...GatsbyImage
                  }
                  title
                  excerpt
                  pageLink {
                    ...PageLinkPageInner
                    ...PageLinkPostInner
                  }
                }
              }
              lower {
                image {
                  ...GatsbyImage
                }
                icon {
                  ...GatsbyImage
                }
                title
                excerpt
                pageLink {
                  ...PageLinkPageInner
                  ...PageLinkPostInner
                }
              }
            }
            bannerMidPlatform {
              image {
                desktop {
                  ...GatsbyImage
                }
                mobile {
                  ...GatsbyImage
                }
              }
              icon {
                ...GatsbyImage
              }
              body
              pageLink {
                ...PageLinkPageInner
                ...PageLinkPostInner
              }
              pageLinkText
            }
            titleCopy2 {
              pretitle
              title
              body
            }
            list {
              title
              items {
                text
              }
            }
          }
        }
      }
    }
  }
`

const PlatformInfographic = ({ content }) => {
  // const content = {
  //   main: {
  //     icon: '',
  //   },
  //   sub: {
  //     items: [
  //       {
  //         icon: '',
  //         title: 'Data',
  //         excerpt:
  //           'Ingest, assess, score, and cleanse data for analytics and decisioning.',
  //       },
  //       {
  //         icon: '',
  //         title: 'Data',
  //         excerpt:
  //           'Ingest, assess, score, and cleanse data for analytics and decisioning.',
  //       },
  //       {
  //         icon: '',
  //         title: 'Data',
  //         excerpt:
  //           'Ingest, assess, score, and cleanse data for analytics and decisioning.',
  //       },
  //     ],
  //   },
  //   lower: {
  //     image: '',
  //     icon: '',
  //     title: 'Marketing Intelligence',
  //     excerpt: 'Data and insights that go beyond benchmarking',
  //     pageLink: '',
  //   },
  // }
  const { main, sub, lower } = content
  return (
    <section className='platform-infographic'>
      <div className='platform-infographic__featured-icon'>
        <ImageWrap
          image={main.icon}
          className='platform-infographic__icon-wrap gatsby-image'
        />
      </div>
      <div className='platform-infographic__tiles-container multivariate-grid multivariate-grid--horizontal-clip multivariate-grid--3'>
        <div className='platform-infographic__tiles-inner-container multivariate-grid__container'>
          <div className='platform-infographic__row multivariate-grid__row'>
            {sub.items.map((item, i) => (
              <PlatformInfographicItem key={i} content={item} />
            ))}
          </div>
        </div>
      </div>
      <div className='platform-infographic__content-card-container'>
        <div className='platform-infographic__content-card-inner-container'>
          <PageLink
            pageLink={lower.pageLink}
            className='content-image-card content-image-card--compact-align-center content-image-card--pointer'
          >
            <ImageWrap
              image={lower.icon}
              className='infographic-bottom-icon content-image-card__icon-wrap gatsby-image'
            />
            <h4 className='content-image-card__title'>{lower.title}</h4>
            <div className='content-image-card__content'>
              <div className='content-image-card__excerpt'>{lower.excerpt}</div>

              <div className='content-image-card__cta transparent'>
                {strings.learnDetails}
              </div>
            </div>
          </PageLink>
        </div>
      </div>
    </section>
  )
}

const PlatformInfographicItem = ({ content }) => {
  return (
    <div className='platform-infographic__tiles-column multivariate-grid__column'>
      <PageLink
        pageLink={content.pageLink}
        className='content-image-card content-image-card--compact content-image-card--pointer'
      >
        <ImageWrap
          className='content-image-card__icon-wrap gatsby-image'
          image={content.icon}
        />
        <h4 className='content-image-card__title'>{content.title}</h4>
        <div className='content-image-card__content'>
          <div className='content-image-card__excerpt'>{content.excerpt}</div>
          <div className='content-image-card__cta'>{strings.learnDetails}</div>
        </div>
      </PageLink>
    </div>
  )
}

const List = ({ content }) => {
  const { title, items } = content
  return (
    <section className='list-separators multivariate-grid'>
      <div className='list-separators__container multivariate-grid__container'>
        <h3 className='list-separators__title'>{title}</h3>
        <ul className='list'>
          {items.map((item, i) => (
            <li key={i} className='list__item'>
              <p>{item.text}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}
