import React from 'react'
import ImageWrap from 'components/ImageWrap'
import PageLink from 'components/PageLink'

export default function BannerMid({ content }) {
  const { image, icon, pretitle, title, body, pageLink, pageLinkText } = content
  return (
    <section className='multivariate-grid multivariate-grid--horizontal-clip hero'>
      {image && (
        <section className='image'>
          {image.desktop && (
            <ImageWrap className='desktop-only' image={image.desktop} />
          )}
          {image.mobile && (
            <ImageWrap className='mobile-only' image={image.mobile} />
          )}
        </section>
      )}

      <div className='hero__container multivariate-grid__container'>
        <div className='hero__inner-container'>
          {icon && (
            <div className='hero__icon' style={{ width: '430px' }}>
              <ImageWrap image={icon} />
            </div>
          )}
          {pretitle && <div className='hero__pretitle'>{pretitle}</div>}
          {title && <h1 className='hero__title hero__title-tag-h1'>{title}</h1>}
          <div
            className='hero__excerpt'
            dangerouslySetInnerHTML={{ __html: body }}
          />
          <div className='hero__cta-list hero__cta-list--1'>
            <PageLink pageLink={pageLink} className='hero__cta'>
              {pageLinkText}
            </PageLink>
          </div>
        </div>
      </div>
    </section>
  )
}
