import React from 'react'

export default function TitleCopy2({ content }) {
  const { pretitle, title, body } = content
  return (
    <section className='multivariate-grid multivariate-grid--horizontal-clip title-copy-block-50-50'>
      <div className='title-copy-block-50-50__container multivariate-grid__container'>
        <div className='tile-copy-block-50-50__row multivariate-grid__row'>
          <div className='title-copy-block-50-50__column multivariate-grid__column'>
            <p className='title-copy-block-50-50__pretitle'>{pretitle}</p>

            <h2
              className='title-copy-block-50-50__title'
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
          <div className='title-copy-block-50-50__column multivariate-grid__column'>
            <div
              className='title-copy-block-50-50__copy-block'
              dangerouslySetInnerHTML={{ __html: body }}
            ></div>
          </div>
        </div>
      </div>
    </section>
  )
}
