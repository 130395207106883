import React from 'react'

export default function TitleCopy({ content }) {
  const { title, body } = content
  return (
    <section className='multivariate-grid multivariate-grid--horizontal-clip title-copy-block-50-50 title-copy-block-50-50--alt-background'>
      <div className='hypercubes__base hypercubes__base-left'></div>
      <div className='hypercubes__base hypercubes__base-right'></div>

      <div className='title-copy-block-50-50__container multivariate-grid__container'>
        <div className='tile-copy-block-50-50__row multivariate-grid__row'>
          <div className='title-copy-block-50-50__column multivariate-grid__column'>
            <h3 className='title-copy-block-50-50__title'>{title}</h3>
          </div>
          <div className='title-copy-block-50-50__column multivariate-grid__column'>
            <div
              className='title-copy-block-50-50__copy-block'
              dangerouslySetInnerHTML={{ __html: body }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
